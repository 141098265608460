import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

import { TypeBannerProps } from "../helpers"

const defaultTitle = `Готовимся к&nbsp;школе с&nbsp;Халвой`

const defaultDesc = `
    — До 36 месяцев рассрочки
    <br/>
    — До 5000 баллов кэшбэка
    <br/>
    — Подарки от партнеров и&nbsp;главный приз – автомобиль
  `

export function Banner({
  buttonText = "Оформить карту",
  orderNum,
  title = defaultTitle,
  description = defaultDesc,
}: TypeBannerProps) {
  return (
    <>
      <section className={styles.section} data-exclude={orderNum}>
        <Container className={styles.container}>
          <p className={styles.someDate}>05.08-15.09</p>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={styles.btnBlock}>
        <Container>
          <Button onClick={() => handleClickBanner("click_cta")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
