// extracted by mini-css-extract-plugin
export var brMob = "banner-module--brMob--GYsYP";
export var brTabDesk = "banner-module--brTabDesk--dadTW";
export var brTabDeskLarge = "banner-module--brTabDeskLarge--vBSsC";
export var btn = "banner-module--btn--uqO-9";
export var btnBlock = "banner-module--btnBlock--qhHTc";
export var container = "banner-module--container--rjG2U";
export var hideMobBlock = "banner-module--hideMobBlock--i+eaj";
export var img = "banner-module--img--MKm41";
export var mobBtn = "banner-module--mobBtn--A-q1R";
export var secondContainer = "banner-module--secondContainer--rgeKm";
export var section = "banner-module--section--qlgmU";
export var someDate = "banner-module--someDate--i9OOx";
export var text = "banner-module--text--0N0ch";